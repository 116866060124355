import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

import WOW from 'wowjs'

import Banner from './banner'

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            banner: 0,
            bannerAnimate: true
        };
      }
      openServicePopup(event) {
        const divsToHide = document.getElementsByClassName('service-popup')
        for(var i = 0; i < divsToHide.length; i++){
            divsToHide[i].style.display = "none";
        }
        document.getElementById('js--' + event.target.getAttribute('data-id')).style.display = 'block'
      }
      closeServicePopup() {
        const divsToHide = document.getElementsByClassName('service-popup')
        for(var i = 0; i < divsToHide.length; i++){
            divsToHide[i].style.display = "none";
        }
      }
      playVideo(event) {
        const id = event.target.getAttribute('data-vidid') - 0
        document.getElementsByClassName("yt-cover-index")[id].style.display = 'none'
        document.getElementById('js--' + id).src += "&autoplay=1";
      }
      componentDidMount() {
        new WOW.WOW().init();
          setInterval(() => {
              this.setState({
                  banner: !this.state.banner
              })
          }, 2000);
      }
    render() {
        return(
  <Layout>
    <SEO title="Cosmetic Dentistry Toronto - Porcelain Veneers | The Smile Stylist" 
    description="The Smile Stylist, the top-rated Cosmetic Dentist in the Greater Toronto Area specializes in porcelain veneers, smile makeovers and crafting unforgettable smiles." 
    keywords="cosmetic dentistry, porcelain veneers, cosmetic dentistry Toronto Smile Makeover"/>
    <main className="site-content" role="main">
    <Banner />
    <section id="about" className="__no-pad">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 wow animated fadeInRight">
            <a href="/before-after" className="__no-hov">
              <div className="welcome-block text-center">
                <h1>
                  View smiles designed by the smile stylist
                  <span><img className="camera-icon" src={ require('../images/desktop/landing/camera-icon.png') } alt="Camera Icon" /></span>
                </h1>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className="__no-pad">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center hidden-lg hidden-md hidden-sm">
            <a href="/contact" className="urlBook-today" title="Book Today"></a>
          </div>
        </div>
      </div>
    </section>


    <section id="service">
      <div className="container posr">
        <div className="row">
          <div className="col-md-4 col-sm-6 col-xs-12 text-center wow animated zoomIn">
            <div className="service-item service-img">
              <p className="model-name">Gretchen Rossi</p>
              <img className="with-space" src={ require('../images/desktop/home/rossi.png') } alt="Gretchen Rossi"/>
              <img src={ require('../images/desktop/home/logo-real-housewives.jpg') } className="no-border" alt="Real Housewives Logo" />
              <p>"Thanks to you I got so many compliments on my smile." - Gretchen Rossi</p>
              <hr className="visible-sm visible-xs" />
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12 text-center wow animated zoomIn" data-wow-delay="0.3s">
            <div className="service-item service-img">
              <p className="model-name">Peta Murgatroyd</p>
              <img src={ require('../images/desktop/home/murgatroyd.png') } alt="Peta Murgatroyd" />
              <img src={ require('../images/desktop/home/logo-dancing.jpg') } className="no-border" alt="Dancing with the Stars Logo"/>
              <p>"Now I know why they call you Dentist to the Stars!" - Peta Murgatroyd</p>
              <hr className="visible-sm visible-xs" />
            </div>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12 text-center wow animated zoomIn" data-wow-delay="0.6s">
            <div className="service-item service-img">
              <p className="model-name">Kat Callaghan</p>
              <img src={ require('../images/desktop/home/callaghan.png') } alt="Kat Callaghan"/>
              <img src={ require('../images/desktop/home/logo-z103.5.jpg') } className="no-border" alt="Z103.5 Logo" />
              <p>"I wouldn't go to anyone else!" - Kat Callaghan &nbsp;&nbsp;&nbsp;</p>
            </div>
          </div>
        </div>
        <a className="test-link" href='/testimonials'>View More</a>
      </div>
    </section>
    
    <section id="products" >
      <div className="container">
        <div className="row">
          <div className="col-sm-6 text-center wow animated zoomIn" data-wow-delay="0.3s">
            <a href="/spa-dentistry"><h2>FIVE STAR Dentistry</h2>
              <img src={ require('../images/desktop/home/5-star.jpg') } alt="Five Star Dentistry "/></a>
            <p>From Bose headset to lavender neck wraps experience spa dentistry like never before.</p>
            <p><a href="/spa-dentistry">Learn more</a></p>
          </div>
          <div className="col-sm-6 text-center wow animated zoomIn" data-wow-delay="0.6s">
            <a href="/virtual-smile-makeover"><h2>Virtual Consultation</h2>
              <img src={ require('../images/desktop/home/virtual-consultation.jpg') } alt="Virtual Consultation"/></a>
            <p>Kickstart your new smile with our easy virtual smile makeover tool.</p>
            <p><a href="/virtual-smile-makeover">Learn more</a></p>
          </div>
        </div>
      </div>
    </section>
    <section id="book">
      <div className="container">
        <div className="row book-border">
          <div className="col-sm-6 text-center wow animated zoomIn book-left-border" data-wow-delay="0.3s">
            <div className="book-left">
              <img src={ require('../images/desktop/home/dr-kaloti-book.png') } alt="Dr Kaloti's Book"/>
              <h2>Meet Dr. Kaloti - The Smile Stylist</h2>
              <p>Dr. Hisham Kaloti provides complete Dental Treatment and Full Mouth Rehabilitation<span className="hidden-sm"> for patients across Canada with a focus on the Greater Toronto Area</span>.
              </p>
            </div>
            <p><a className="btn btn-black" href="/about-dr-kaloti">Learn more</a></p>
          </div>
          <div className="col-sm-6 text-center wow animated zoomIn" data-wow-delay="0.6s">
            <div className="book-right">
              <img src={ require('../images/desktop/mgr.png') } alt="Dr Kaloti's Magazine" />
              <h2>The Smile Stylist Magazine</h2>
              <p>Learn more about Dr. Kaloti’s mission to Haiti, see how to achieve a celebrity smile, take our very own smile design quiz and more!</p>
            </div>
            <p>
              <a className="btn btn-black" target="_blank" href="/Smilestylist Magazine - The Smile Stylist.pdf">Download</a>
            </p>
          </div>
        </div>
        <div className="row home_video_wrapper">
          <div className="col-sm-12 text-center wow animated zoomIn" data-wow-delay="0.2s">
            <h2>
              <img alt="The Smile Stylist Logo" src={ require('../images/desktop/logo.png') } alt="Book Logo" className="book-logo" />continues to receive national recognition for cosmetic dentistry.
            </h2>
            <p>&nbsp;</p>
          </div>
          <div className="col-sm-12 col-md-4 text-center wow animated zoomIn yt-video-container" data-wow-delay="0.2s">
            <img alt="The Smile Stylist Video" src={ require('../images/desktop/home/first-video.jpg') } onClick={this.playVideo} data-vidid="0" className="yt-cover-index" />
            <iframe width="560" height="315" src="https://www.youtube.com/embed/JsP4A3JbafE?rel=0&amp;showinfo=0" frameBorder="0" id="js--0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
          </div>
          <div className="col-sm-12 col-md-4 text-center wow animated zoomIn yt-video-container" data-wow-delay="0.4s">
            <img alt="Porcelain Veneers Video " src={ require('../images/desktop/home/second-video.jpg') } onClick={this.playVideo} data-vidid="1" className="yt-cover-index" />
            <iframe width="560" height="315" src="https://www.youtube.com/embed/ImV5ByMuZS4?rel=0&amp;showinfo=0" frameBorder="0" id="js--1" allow="autoplay; encrypted-media" allowFullScreen></iframe>
          </div>
          <div className="col-sm-12 col-md-4 text-center wow animated zoomIn yt-video-container" data-wow-delay="0.6s">
            <img alt="Whitney Thompson Interview Video " src={ require('../images/desktop/home/third-video.jpg') } onClick={this.playVideo} data-vidid="2" className="yt-cover-index" />
            <iframe id="ss-vid-3" src="https://www.youtube.com/embed/eIuAkFysL4g?rel=0&amp;showinfo=0" frameBorder="0" id="js--2" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </section>
    <section>
        <img className="ss-smiles-title" src={ require('../images/desktop/home/smiles-title.png') } alt="Black and White Logo "/>
        <div className="container ss-smiles-con">
            <div className="row ss-smiles">
                <div onClick={this.openServicePopup} data-id="3" className="col-sm-3 js--img-open-popup"></div>
                <div onClick={this.openServicePopup} data-id="29" className="col-sm-3 js--img-open-popup"></div>
                <div onClick={this.openServicePopup} data-id="26" className="col-sm-3 js--img-open-popup"></div>
                <div onClick={this.openServicePopup} data-id="15" className="col-sm-3 js--img-open-popup"></div>
            </div>
            <div className="row ss-smiles">
                <div onClick={this.openServicePopup} data-id="19" className="col-sm-3 js--img-open-popup"></div>
                <div onClick={this.openServicePopup} data-id="18" className="col-sm-3 js--img-open-popup"></div>
                <div onClick={this.openServicePopup} data-id="6" className="col-sm-3 js--img-open-popup"></div>
                <div onClick={this.openServicePopup} data-id="7" className="col-sm-3 js--img-open-popup"></div>
            </div>
            <div className="row ss-smiles">
                <div onClick={this.openServicePopup} data-id="8" className="col-sm-3 js--img-open-popup"></div>
                <div onClick={this.openServicePopup} data-id="17" className="col-sm-3 js--img-open-popup"></div>
                <div onClick={this.openServicePopup} data-id="28" className="col-sm-3 js--img-open-popup"></div>
                <div onClick={this.openServicePopup} data-id="2" className="col-sm-3 js--img-open-popup"></div>
            </div>
            <div className="row ss-smiles">
                <div onClick={this.openServicePopup} data-id="20" className="col-sm-3 js--img-open-popup"></div>
                <div onClick={this.openServicePopup} data-id="25" className="col-sm-3 js--img-open-popup"></div>
                <div onClick={this.openServicePopup} data-id="5" className="col-sm-3 js--img-open-popup"></div>
                <div onClick={this.openServicePopup} data-id="1" className="col-sm-3 js--img-open-popup"></div>
            </div>
        </div>
    </section>
    <div id="js--1" className="service-popup">
      <div className="service-popup__containter">
        <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
        <p className="service-popup__title">
          Gum Lift + Porcelain Veneers
        </p>
        <div className="service-popup__img-container">
          <img onClick={this.openServicePopup} data-id="24" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
          <img className="service-popup__image" src={ require('../images/desktop/services/ba-gum.jpg') } title="Gum Lift + Porcelain Veneers" />
          <img onClick={this.openServicePopup} data-id="2" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
        </div>
        <p className="service-popup__desc">
          Gum lifting, also known as "gingivectomy", is a cosmetic dentistry procedure which improves the aesthetics of your smile by ensuring the gum line of your teeth is in the best possible position to show off your teeth.
        </p>
        <div className="service-popup__btns">
          <a href="/gum-lift-procedures" className="service-popup__btn_black">Learn More</a>
          <a href="/contact" className="service-popup__btn_red">Free Consult</a>
        </div>
      </div>
    </div>
    <div id="js--2" className="service-popup">
      <div className="service-popup__containter">
        <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
        <p className="service-popup__title">
          Porcelain Veneers
        </p>
        <div className="service-popup__img-container">
          <img onClick={this.openServicePopup} data-id="1" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
          <img className="service-popup__image" src={ require('../images/desktop/services/porcelain-veneers-before-after.png') } />
          <img onClick={this.openServicePopup} data-id="3" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
        </div>
        <p className="service-popup__desc">
          Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
        </p>
        <div className="service-popup__btns">
          <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
          <a href="/contact" className="service-popup__btn_red">Free Consult</a>
        </div>
      </div>
    </div>
    <div id="js--3" className="service-popup">
      <div className="service-popup__containter">
        <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
        <p className="service-popup__title">
          ClearCorrect Invisible Braces
        </p>
        <div className="service-popup__img-container">
          <img onClick={this.openServicePopup} data-id="2" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
          <img className="service-popup__image" src={ require('../images/desktop/services/ClearCorrect Invisible Braces.png') } />
          <img onClick={this.openServicePopup} data-id="4" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
        </div>
        <p className="service-popup__desc">
          Cosmetic teeth whitening is a big part of our practice.
        </p>
        <div className="service-popup__btns">
          <a href="/teeth-whitening" className="service-popup__btn_black">Learn More</a>
          <a href="/contact" className="service-popup__btn_red">Free Consult</a>
        </div>
      </div>
    </div>
    <div id="js--4" className="service-popup">
      <div className="service-popup__containter">
        <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
        <p className="service-popup__title">
          Gum Lift Procedures
        </p>
        <div className="service-popup__img-container">
          <img onClick={this.openServicePopup} data-id="3" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
          <img className="service-popup__image" src={ require('../images/desktop/services/ba-veneers1.jpg') } title="Gum Lift + Porcelain Veneers" />
          <img onClick={this.openServicePopup} data-id="5" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
        </div>
        <p className="service-popup__desc">
          Gum lifting, also known as "gingivectomy", is a cosmetic dentistry procedure which improves the aesthetics of your smile by ensuring the gum line of your teeth is in the best possible position to show off your teeth.
        </p>
        <div className="service-popup__btns">
          <a href="/gum-lift-procedures" className="service-popup__btn_black">Learn More</a>
          <a href="/contact" className="service-popup__btn_red">Free Consult</a>
        </div>
      </div>
    </div>
      <div id="js--5" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Dental Implants
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="4" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Dental Implants.png') } />
            <img onClick={this.openServicePopup} data-id="6" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            If severe dental problems are causing you discomfort, you may be a good candidate for a full mouth reconstruction.
          </p>
          <div className="service-popup__btns">
            <a href="/full-mouth-reconstruction" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--6" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="5" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Caroline Before After.png') } title="Caroline Before After" />
            <img onClick={this.openServicePopup} data-id="7" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--7" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="6" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Before After Porcelain Veneers 3.png') } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="8" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--8" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="7" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Before After Porcelain Veneers 4.png') } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="9" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--9" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Implants + Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="7" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Implants Porcelain Veneers.png') } title="Implants + Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="10" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--11" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="10" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Before After Porcelain Veneers 5.png') } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="12" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--12" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="11" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Before After Porcelain Veneers 6.png') } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="13" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--13" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Gapped Teeth + Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="12" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Before After Veneers 7.png') } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="14" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--14" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="13" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Before After Veneers 8.png') } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="15" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--15" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="14" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Before After Veneers 9.png') } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="16" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--16" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Full Mouth Reconstruction
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="15" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Full Mouth Reconstruction.png') } />
            <img onClick={this.openServicePopup} data-id="17" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            If severe dental problems are causing you discomfort, you may be a good candidate for a full mouth reconstruction.
          </p>
          <div className="service-popup__btns">
            <a href="/full-mouth-reconstruction" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--17" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="16" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Ashley Before - After.png') } title="Ashley Before - After" />
            <img onClick={this.openServicePopup} data-id="18" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--18" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="17" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Before After Veneers 10.png') } title="Ashley Before - After" />
            <img onClick={this.openServicePopup} data-id="19" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--19" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="18" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Before After Veneers 11.png') } title="Ashley Before - After" />
            <img onClick={this.openServicePopup} data-id="20" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--20" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="19" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Before After Veneers 12.png') } title="Ashley Before - After" />
            <img onClick={this.openServicePopup} data-id="21" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--21" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Full Mouth Reconstruction
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="20" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Full Mouth Reconstruction 2.png') } />
            <img onClick={this.openServicePopup} data-id="22" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            If severe dental problems are causing you discomfort, you may be a good candidate for a full mouth reconstruction.
          </p>
          <div className="service-popup__btns">
            <a href="/full-mouth-reconstruction" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--22" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Dental Implants
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="21" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Dental Implants 2.png') } title="Dental Implants" />
            <img onClick={this.openServicePopup} data-id="23" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            If severe dental problems are causing you discomfort, you may be a good candidate for a full mouth reconstruction.
          </p>
          <div className="service-popup__btns">
            <a href="/full-mouth-reconstruction" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--23" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="22" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Before After Veneers 13.png') } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="24" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--24" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="23" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/Before After Veneers 14.png') } title="Porcelain Veneers" />
            <img onClick={this.openServicePopup} data-id="25" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
      <div id="js--25" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="24" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/add-1.png') } title="Ashley Before - After" />
            <img onClick={this.openServicePopup} data-id="26" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
            <div id="js--26" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="25" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/add-2.png') } title="Ashley Before - After" />
            <img onClick={this.openServicePopup} data-id="27" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
            <div id="js--27" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="26" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/add-3.png') } title="Ashley Before - After" />
            <img onClick={this.openServicePopup} data-id="28" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
            <div id="js--28" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="27" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/add-4.png') } title="Ashley Before - After" />
            <img onClick={this.openServicePopup} data-id="29" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
            <div id="js--29" className="service-popup">
        <div className="service-popup__containter">
          <span onClick={this.closeServicePopup} className="service-popup__close-btn">X</span>
          <p className="service-popup__title">
            Porcelain Veneers
          </p>
          <div className="service-popup__img-container">
            <img onClick={this.openServicePopup} data-id="228" className="service-popup__arrow_left js--open-popup" src={ require('../images/desktop/arrow-left.svg') } />
            <img className="service-popup__image" src={ require('../images/desktop/services/add-5.png') } title="Ashley Before - After" />
            <img onClick={this.openServicePopup} data-id="1" className="service-popup__arrow_right js--open-popup" src={ require('../images/desktop/arrow-right.svg') } />
          </div>
          <p className="service-popup__desc">
            Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth,<br />while also providing strength and resilience comparable to natural tooth enamel.
          </p>
          <div className="service-popup__btns">
            <a href="/porcelain-veneers" className="service-popup__btn_black">Learn More</a>
            <a href="/contact" className="service-popup__btn_red">Free Consult</a>
          </div>
        </div>
      </div>
    <section id="testimonials" className="parallax">
      <div className="overlay">
        <div className="container">
          <div className="row">
            <div className="sec-title text-center white wow animated fadeInDown">
              <h2>Featured in Magazines</h2>
            </div>
            <div className="wow animated fadeInUp visible-xs visible-sm visible-md visible-lg">
              <div className="text-center">
                <h3>Women's Health</h3>
                <p>Whitening tips in Women's Health provided by <img alt="The Smile Stylist Logo" src={ require('../images/desktop/logo.png') } className="mag-logo" /></p>
                <img src={ require('../images/desktop/home/mag-women-health.jpg') } className="img-responsive" alt="Women's Health Magazine" />
              </div>
              <div className="text-center">
                <h3>InStyle</h3>
                <p>InStyle Magazine quoted us for tips on 10 ways to smile brighter.</p>
                <img src={ require('../images/desktop/home/mag-instyle.jpg') } className="img-responsive" alt="InStyle Magazine" />
              </div>
              <div className="text-center">
                <h3>Men's Health</h3>
                <p>
                  Find out what made one patient make his choice to come to
                  <img src={ require('../images/desktop/logo.png') } alt="The Smile Stylist Logo" className="mag-logo" />
                  for his cosmetic dentistry revision in Men's Health May 2014 issue.
                </p>
                <img src={ require('../images/desktop/home/mag-men-health.jpg') } className="img-responsive" alt="Man's Health Magazine" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="sec-title text-center wow animated fadeInDown">
            <h2 className="black">Full Mouth Reconstruction vs. Smile Makeover – Which to Choose?</h2>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 wow animated fadeInLeft">
            <p>So how does full mouth reconstruction differ from smile makeover? A smile makeover is something that you elect to have performed, while a full mouth reconstruction is something that you need.</p>
            <p>As a premier cosmetic dental office, we pride ourselves in being on the forefront of responding to the increasing consumer demands for beautiful, natural-looking dentistry. However, for some patients, it is becoming hard to draw a line between purely "cosmetic" (such as elective) dentistry and "restorative" (necessary) dentistry.</p>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 wow animated fadeInRight">
            <p>If you need full mouth reconstruction, the materials and techniques we utilize in our practice today make it possible for us to provide you with durable, functional and clinically sound treatments that also delivers that signature "Smile Stylist" smile.</p>
            <p>It is also important to note that a smile makeover – though performed primarily to improve the aesthetic appearance of the smile – requires the use of clinically proven dental materials and treatment techniques, as well as exceptional knowledge, training and skill on the part of the dentist.</p>
          </div>
        </div>
      </div>
    </section>


    <section id="main-white" className="haiti">
      <div className="container-fluid">
        <div className="row pad80">
          <div className="left-left col-sm-6 col-md-4 text-center sec-title wow animated fadeInDown">
            <h2 className="black">Dental Mission Haiti</h2>
            <p><a className="btn btn-black" href="/giving-back">Learn more</a></p>
          </div>
        </div>
      </div>
    </section>
    </main>
  </Layout>
        )
    }
}

export default IndexPage
