import React from "react"
import { Link } from "gatsby"
import { Fade } from 'react-slideshow-image';

const fadeProperties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: false
  }
  
const Banner = () => {
    return (
      <Link to="/before-after" className="slide-container">
        <Fade {...fadeProperties}>
          <div className="each-fade">
            <div className="image-container">
              <img src={ require('../images/desktop/home/banner-2.jpg') } />
            </div>
          </div>
          <div className="each-fade">
            <div className="image-container">
              <img src={ require('../images/desktop/home/banner-1.jpg') } />
            </div>
          </div>
        </Fade>
      </Link>
    )
  }

export default Banner